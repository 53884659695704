var ui = {
    wow:function () {
        wow = new WOW({
            boxClass: 'wow', // default
            animateClass: 'animated', // default
            offset: 300, // default
            mobile: false, // default
            live: true // default
        });
        wow.init();
        
    },
    modals: function(){
        $('.modal-but').on('click',function(){
            if($(this).get(0).tagName != 'DIV'){
                var type = $(this).attr('href')
            }else{
                var type = '#'+$(this).data('modal');
            }
            $(type).fadeIn(400).toggleClass('window-open');
            $('.wrap').addClass('no-scroll');
            return false;
        });
        $('.modal').on('click',function(e){
            var div = $(".modal .modal-dialog");
            if (!div.is(e.target)
                && div.has(e.target).length === 0) {
                if ($(this).parents('modal').hasClass('modal-appartment') ) {
                    $('.modal-appartment-back').click()
                }else {
                    $('.closeModal').click();
                }
            }
        });
        $('.close').on('click',function(){
            $('.modal.window-open').fadeOut(300).removeClass('window-open');
            $('#videos iframe').attr('src', '');
            $('.wrap').removeClass('no-scroll')
        })
        $('.modal-appartment-back').on('click',function(){
            $('.modal.window-open').removeClass('window-open');
            return false;
        })
        
    },
    phoneInputes: function () {
        $('[name="phone"]').mask("+7 (999) 999-99-99");
    },
    mobMenu: function () {
        $('.burger-item .icon-menu').on('click', function(){
            $('body').toggleClass('mobile-menu-is-open');
            return false;
        });
    },
    swipebox: function () {
        $( '.zoom' ).swipebox({
            removeBarsOnMobile: false,
            hideBarsDelay : 0
        });
    },
    typedText: function () {
        var cicle = 0;
        var delTetxFunction = function (cicle) {
            var clear = setInterval(function () {
                if ($('.top-screen-title span').html().length) {
                    $('.top-screen-title span').html($('.top-screen-title span').html().slice(0, -1));
                }else{
                    clearInterval(clear);
                    setTimeout(function() { 
                        typedFunction(cicle);
                    }, 700);
                }
            },200)
        }
        var typedFunction = function (text) {
            var string = $('.top-screen-title span').attr('data-typedtext').replace(/\s+/g,'').split(',');
            if (!text || text != 1) {
                var str = string[0];
                $('.top-screen-title span').html('');
                cicle = 1;
            }else{
                var str = string[1];
                $('.top-screen-title span').html('')
                cicle = 0;
            }
            var data = $('.top-screen-title span');
            var len = str.length;
            var i = 0;
            var tId = setInterval(function () {
                if (str[i]) {
                    data.html(data.html() + str[i])
                }
                i++;
                if (i > len) {
                    clearInterval(tId);
                    setTimeout(function() { 
                        delTetxFunction(cicle);
                    }, 700);
                }
            }, 400);
        };
        if ($('.top-screen-title span').length) {
            typedFunction();
        }
    },
    resImages: function () {
        $('.res-images').each(function(){
            if ($(this).children('img').length) {
                var bg = $(this).children('img').attr('src');
                $(this).css({
                    'background-image':'url(' + bg + ')'
                }).children('img').css({'display':'none'})
            }
        })
    },
    mainInit: function () {
        this.wow();
        this.modals();
        this.phoneInputes();
        this.swipebox();
        this.mobMenu();
        this.typedText();
        this.resImages();
    }
}

priceSet = function(data){
    if(typeof data !== 'string') {
        var data = data.toString();
    }
    data = Number(data.replace(/\s+/g,''));
    if (data >= 0) {
        var price       = Number.prototype.toFixed.call(parseFloat(data) || 0, 0),
            price_sep   = price.replace(/(\D)/g, ","),
            price_sep   = price_sep.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
        return price_sep;
    }else{
        return '0';
    }
};
$(document).ready(function(){
    ui.mainInit();
    $('.footer-form input').on('change',function(){
        $('.footer-form input[name="comment"]').val('');
        var rooms = '';
        $('.footer-form .rooms-list input[type="checkbox"]').each(function(){
            if ($(this).prop('checked') == true) {
                rooms = rooms + $(this).val() + ',';
            }
        });
        if (rooms != '') {
            rooms = rooms.slice(0, -1) + '; ';
        }
        var price = '';
        if ($('.footer-form input[name="price-from"]').val() != '' || $('.footer-form input[name="price-to"]').val() != '' ) {
            if ($('.footer-form input[name="price-from"]').val() != '') {
                $('.footer-form input[name="price-from"]').val(priceSet($('.footer-form input[name="price-from"]').val()))
            }
            if ($('.footer-form input[name="price-to"]').val() != '') {
                $('.footer-form input[name="price-to"]').val(priceSet($('.footer-form input[name="price-to"]').val()))
            }
        }
        // if ($('.footer-form input[name="price-from"]').val() != '' && $('.footer-form input[name="price-to"]').val() != '' ) {
            price = 'Стоимость: ' + $('.footer-form input[name="price-from"]').val() + ' - ' + $('.footer-form input[name="price-to"]').val() + '; ';
        // }
        var raion = 'Район: ' + $('.footer-form select[name="raion"]').val() + '; ';
        $('.footer-form input[name="comment"]').val('Количество комнат: ' + rooms + price + raion)
    })
});
$(window).resize(function(){
    
})
$(document).mouseup(function (e){
    
});